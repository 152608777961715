////////////////////////////////////////////////////////////////////////////////////
// MODULE IMPORTS:
////////////////////////////////////////////////////////////////////////////////////
import Parallax from 'parallax-js';

var popup = require("jquery-popup-overlay"); ////////////////////////////////////////////////////////////////////////////////////
// GLOBAL VARS:
////////////////////////////////////////////////////////////////////////////////////


var ww; // window width

var wh; // window width

var hh;
var selectedClassName = 'selected'; // the selected class name for menu items

var menuOpenClassName = 'open'; // the class that defines the open state of the menu

var fadeInSpeed = 50; // the fade in transition speed

var fadeOutSpeed = 75; // the fade out transition speed

var navMouseOutDelay = 600; // the delay before the nav disappears on mouseout

var mouseOutTimer; // the MouseOut timeout timer 

var is_responsive = false;
var $header;
var lastScrollTop = 0; // used for tracking scroll position and direction

var scrollDirection = "down"; // default

function refreshScrollData() {
  // Gets the scrollTop and direction and updates the global variables accordingly
  var st = window.pageYOffset || document.documentElement.scrollTop;

  if (st > lastScrollTop) {
    scrollDirection = "down";
  } else {
    scrollDirection = "up";
  }

  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}

function refreshDimensions() {
  // update calculated dimensions (to be used on resize events, or anytime a manual refresh is required)
  ww = $(window).width();
  wh = $(window).height();
  hh = $("header").outerHeight(); // ### Check if we are in Mobile (ie Responsive mode)

  if ($('.mobileHeader').css('display') === 'block') {
    is_responsive = true;
  } else {
    is_responsive = false;
  }

  refreshScrollData(); // refresh scroll data just in case...
} ////////////////////////////////////////////////////////////////////////////////////
// MAIN DOCUMENT READY
////////////////////////////////////////////////////////////////////////////////////


$(document).ready(function () {
  $header = $("#wrapper > header");
  refreshDimensions();
  $(window).trigger('resize');
}); ////////////////////////////////////////////////////////////////////////////////////
// STICKY HEADER
////////////////////////////////////////////////////////////////////////////////////
//$(document).ready(function () {
//    var sticky = new Waypoint.Sticky({
//        element: $('.header')[0]
//    });
//});
////////////////////////////////////////////////////////////////////////////////////
// SCROLL EVENTS
////////////////////////////////////////////////////////////////////////////////////

$(window).on("scroll", function () {
  $header = $("#wrapper > header");
  refreshScrollData(); // Scroll-to-top link

  if (lastScrollTop > 450) {
    $('.scrollToTop').fadeIn();
  } else {
    $('.scrollToTop').fadeOut();
  } //$header.removeClass("down up").addClass(scrollDirection);
  //if (lastScrollTop === 0) {
  //    $header.addClass("top");
  //} else {
  //    $header.removeClass("top");
  //}

});
var hasRunCountUpAnimation = false;

if ($(".factGroup").length) {
  var waypoint = new Waypoint({
    element: $(".factGroup").get(0),
    //document.getElementById('waypoint'),
    handler: function handler() {
      if (!hasRunCountUpAnimation) {
        var $countUpInstances = $(".countUp");

        if ($countUpInstances.length) {
          $countUpInstances.each(function () {
            var $countUpInstance = $(this);
            var startVal = $countUpInstance.data("start-value");
            var endVal = $countUpInstance.data("end-value");
            numAnim = new CountUp($countUpInstance.attr("id"), startVal, endVal);

            if (!numAnim.error) {
              numAnim.start();
            } else {
              console.error(numAnim.error);
            }
          });
          hasRunCountUpAnimation = true;
        }
      }
    },
    offset: "60%"
  });
} //////////////////////////////////////////////////////////////////////////////////////////////////////////
/// SCROLL TO LOCATION ON PAGE
//////////////////////////////////////////////////////////////////////////////////////////////////////////


$(document).ready(function () {
  function scrollToLocation(scrollTo, animate, speed) {
    speed = typeof speed !== 'undefined' ? speed : 500;
    animate = typeof animate !== 'undefined' ? animate : true;
    scrollTo = $(scrollTo);
    var container = $('html, body');

    if (animate) {
      container.animate({
        scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
      }, speed);
    } else {
      // Required so that element on page is loaded
      setTimeout(function () {
        container.scrollTop(scrollTo.offset().top - container.offset().top + container.scrollTop());
      }, 250);
    }
  }
}); // ###### Scroll to Tab Content
// ### Click event for the "Scroll to Tab" for TabStrip (works for all RadTabs)

$(document).on("click", ".RadTabStrip a.rtsLink", function () {
  if (is_responsive) {
    scrollToAnchorTab();
  }
}); // ### "Scroll to Tab Content function

function scrollToAnchorTab() {
  var aTag = $(".tabContainer"); // need to add this class "tabContainer" on any manually added RadMultiPage

  $('html,body').animate({
    scrollTop: aTag.offset().top
  }, 'slow');
} // ###### Scroll to Top
// ### Check to see if the window is top if not then display "Scroll to Top" button
// ### Click event for the "Scroll to Top" button


$(document).on('click', '.scrollToTop', function () {
  $('html, body').animate({
    scrollTop: 0
  }, 800);
  return false;
}); ////////////////////////////////////////////////////////////////////////////////////
// WINDOW RESIZING
////////////////////////////////////////////////////////////////////////////////////

$(window).on('resize', function () {
  refreshDimensions();
}); //////////////////////////////////////////////////////////////////////////////////////////////////////////
// SLIDERS / CAROUSELS (NOTE: it's probably better to add these in the slider View)
//////////////////////////////////////////////////////////////////////////////////////////////////////////
// ToDo: move these into their respective Views
// GLOBAL SLIDER SETTINGS

$(".slick-slider > button").on("click", function () {
  $(this).closest(".slick-slider").slick('slickPause');
  scrollUpDown1px();
});
$('.slick-slider').on('swipe', function () {
  $(this).slick('slickPause');
  scrollUpDown1px();
});
$(document).ready(function () {
  //// Resources Slider
  var resourcesSlideshow = $('.resourcesSlider').slick({
    dots: false,
    slide: '.slide',
    autoplay: false,
    autoplaySpeed: 7000,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [{
      breakpoint: 1024,
      settings: {
        autoplay: false
      }
    }]
  }); //// Resources Slider
  //var expertiseSlideshow = $('.expertiseSlider').slick({
  //    dots: false,
  //    slide: '.slide',
  //    autoplay: false,
  //    autoplaySpeed: 7000,
  //    pauseOnHover: true,
  //    slidesToShow: 3,
  //    slidesToScroll: 3,
  //    responsive: [
  //        {
  //            breakpoint: 1024,
  //            settings: {
  //                autoplay: false,
  //            }
  //        }
  //    ]
  //});
}); /////////////////////////////////////////////////////////
//// AOS
//// looks for items with data-aos="animation_name" attribute
//// usage: <div data-aos="animation_name">
//// credit: https://github.com/michalsnik/aos
/////////////////////////////////
//var staggerme = $("[data-aos-stagger=true]");
//var staggerCounter = 0;
//staggerme.each(function () {
//    $(this).attr("data-aos-delay", staggerCounter);
//    staggerCounter += 100;
//});
//AOS.init();
//////////////////////////////////////////////////////////////////////////////////////////////////////////
///SEARCH BOX FUNCTIONALITY
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//$(function () {
//    var searchWrapper = $('.sfsearchBox');
//    var searchInput = searchWrapper.find('input[name="search"]');
//    var searchIndex = searchWrapper.find('input[id="hdnSearchIndex"]');
//    var searchLocation = searchWrapper.find('input[id="hdnSearchLocation"]');
//    var searchButton = searchWrapper.find('button');
//    searchButton.click(function (e) {
//        e.preventDefault();
//        var index = searchIndex.val();
//        var value = searchInput.val().trim();
//        var location = searchLocation.val().trim();
//        if (index && value !== '' && location !== '') {
//            window.location = location
//                + '?indexCatalogue='
//                + index + '&searchQuery='
//                + value + '&wordsMode=0';
//        }
//    });
//    searchInput.keypress(function (e) {
//        var key = e.which;
//        // the enter key code
//        if (key === 13) {
//            searchButton.click();
//            return false;
//        }
//    });
//});
//////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Counters (using jquery.counter-up.js and waypoints)
//////////////////////////////////////////////////////////////////////////////////////////////////////////

$(document).ready(function () {
  var $counters = $('.counter');

  if ($counters.length) {
    $counters.each(function (i, counter) {
      // No need to call waypoints manually ... the counter-up plugin has the call built-in
      $(counter).counterUp({
        delay: 20,
        time: 2000
      });
    });
  }
}); //////////////////////////////////////////////////////////////////////////////////////////////////////////
/// LAZY   http://jquery.eisbehr.de/lazy/
//////////////////////////////////////////////////////////////////////////////////////////////////////////

$('.lazy').lazy({
  threshold: 0 // images will load when 100 px from viewport edge

}); // Needed for lazy loading in some sliders to load images on click/swipe of next slide

function scrollUpDown1px() {
  setTimeout(function () {
    $(window).scrollTop($(window).scrollTop() - 1);
    $(window).scrollTop($(window).scrollTop() + 1);
  }, 250);
} //////////////////////////////////////////////////////////////////////////////////////////////////////////
/// FORMS / AUTO-FILL
//////////////////////////////////////////////////////////////////////////////////////////////////////////
// SEARCH BOX - PLACEHOLDER TEXT


$('.search input[type="text"]').attr("placeholder", " Search "); // Fix for Chrome Form Autofill for our contact form

$(".contactForm input[name*=Name]").attr('autocomplete', 'name');
$(".contactForm input[name*=Email]").attr('autocomplete', 'email');
$(".contactForm input[name*=Phone]").attr('autocomplete', 'tel'); // Or you could turn it off
//$('form').attr('autocomplete', 'off');
//////////////////////////////////////////////////////////////////////////////////////////////////////////
// Misc
//////////////////////////////////////////////////////////////////////////////////////////////////////////
// Expand Expertise section (Home Page)

$(".btnExpandExpertise").on("click", function () {
  $(".expertiseExpandable").slideToggle({
    duration: 300,
    easing: "swing"
  });
}); // Clickable items (e.g. a clickable article card with nested links)

$(document).on("click", ".clickable", function (event) {
  var $this = $(this); // If there's not data-url, then find the first child el with an href and use that

  var itemUrl = "";
  var target = "_self"; // default
  // First check for data atts. If none, use the first child link

  if (typeof $this.data("url") !== 'undefined' && $this.data("url").length) {
    itemUrl = $this.data("url");

    if (typeof $this.data("target") !== 'undefined' && $this.data("target").length) {
      target = $this.data("target");
    }
  } else {
    var $firstLinkFound = $this.find("a[href]").first();
    itemUrl = $firstLinkFound.attr("href");
    target = $firstLinkFound.attr("target") ? $firstLinkFound.attr("target") : target;
  }

  window.open(itemUrl, target);
}); //////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Resize Items based on the content inside them. Useful for sliders so that items remain same height
/// called like in window resize event: resizeItemsVertically($('.servicesList'), $('.servicesList .items .item .bottom'));
//////////////////////////////////////////////////////////////////////////////////////////////////////////

function resizeItemsVertically(baseElement, itemHeightElement) {
  if (baseElement.length > 0) {
    // check for element
    var itemsMaxHeight = 0;
    itemHeightElement.each(function () {
      var thisItemHeight = $(this).height();

      if (thisItemHeight > itemsMaxHeight) {
        itemsMaxHeight = thisItemHeight;
      }
    });
    itemHeightElement.height(itemsMaxHeight);
  }
} // Clear Text Selection


function clearSelection() {
  if (document.selection) {
    document.selection.empty();
  } else if (window.getSelection) {
    window.getSelection().removeAllRanges();
  }
} // Parallax
//$('.relatedItems').parallax({ imageSrc: 'http://localhost:10165/images/default-source/default-album/bearb60534826168616da574ff000044313a.png?sfvrsn=326d55d5_0' });
// Email link warning message


$(document).ready(function () {
  $('a[href^="mailto"]').on("click", function () {
    return confirm("Sending an e-mail to us will not make us your lawyers.  Please do not include any confidential information in your e-mail.");
  });
}); // accordianUL 

$(document).ready(function () {
  var groupClass = "group";
  var expandedClass = "active";
  $(".accordionUL span.toggle").on("click", function () {
    toggleAccordion($(this).parent());
    return false;
  }).on("keypress", function (e) {
    if (e.which === 13) {
      // enter key code
      toggleAccordion($(this).parent());
      return false;
    }
  }); // Toggle the target accordion

  function toggleAccordion($accordion) {
    if ($accordion.hasClass(expandedClass)) {
      collapseAccordion($accordion);
    } else {
      expandAccordion($accordion);
    }
  }

  function expandAccordion($accordion) {
    $(".accordionUL li." + expandedClass + ">ul").slideUp();
    $(".accordionUL li").removeClass(expandedClass);
    $accordion.addClass(expandedClass).children('ul').slideDown();
  } // Close single accordion (and update group)


  function collapseAccordion($accordion) {
    $(".accordionUL li").removeClass(expandedClass);
    $accordion.children('ul').slideUp();
  }
}); // newsletters 

$(document).ready(function () {
  var expandedClass = "active";
  $("div.toggleAction").on("click", function () {
    toggleContent($(this).next(".toggleContent"));
    return false;
  }).on("keypress", function (e) {
    if (e.which === 13) {
      // enter key code
      toggleContent($(this).next(".toggleContent"));
      return false;
    }
  }); // Open the First
  // $("div.toggleAction").first().trigger('click');
  // Toggle the Content

  function toggleContent($toggleSection) {
    if ($toggleSection.parent().hasClass(expandedClass)) {
      collapseToggleContent($toggleSection);
    } else {
      updateGroup($toggleSection);
      expandToggleContent($toggleSection);
    }
  }

  function updateGroup($toggleSection) {
    var groupName = $toggleSection.parent().attr("data-accordion-group");

    if (groupName) {
      $("[data-accordion-group='" + groupName + "']").each(function () {
        collapseToggleContent($(this).find(".toggleContent"));
      });
    }
  }

  function expandToggleContent($toggleSection) {
    $toggleSection.parent().addClass(expandedClass);
    $toggleSection.slideDown();
  } // Close single accordion (and update group)


  function collapseToggleContent($toggleSection) {
    $toggleSection.parent().removeClass(expandedClass);
    $toggleSection.slideUp();
  }
});